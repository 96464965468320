document.addEventListener('DOMContentLoaded', function (e) {
    const currency_formatter = new Intl.NumberFormat(document.documentElement.lang, {style: 'currency', currency: 'EUR'});

    const adresse_diff = document.getElementById('adresse_diff'); // checkbox qui indique si on utilise la même adresse de facturation ou pas
    const adresse_facture = document.getElementById('adresse_facture');

    const pays_livr_selector = document.getElementById('adresse[LIV][pays-selector]')
    const pays_fact_selector = document.getElementById('adresse[FAC][pays-selector]')

    const panier_table      = document.querySelectorAll('.panier-totaux');

    /** On affiche ou on masque le fieldset de l'adresse de facturation, en fonction de choix du client d'utiliser l'adresse de livraison pour les deux ou pas. */
    if (adresse_diff instanceof HTMLInputElement && adresse_facture instanceof HTMLFieldSetElement && pays_livr_selector instanceof HTMLSelectElement && pays_fact_selector instanceof HTMLSelectElement) {
        adresse_diff.addEventListener('change', function (e) {
            updateAdresseDiff(adresse_diff);
        })

        pays_livr_selector.addEventListener('change', function (e) {
            updatePays(adresse_diff, pays_livr_selector, pays_fact_selector, panier_table);
        });

        pays_fact_selector.addEventListener('change', function (e) {
            updatePays(adresse_diff, pays_livr_selector, pays_fact_selector, panier_table);
        });

        updateAdresseDiff(adresse_diff);
        updatePays(adresse_diff, pays_livr_selector, pays_fact_selector, panier_table);
    }

    function updateMontant(panier, tva_requise) {
        const tva_element = panier.querySelector('.panier-montant-tva');
        const ttc_element = panier.querySelector('.panier-montant-ttc');

        const ht  = parseFloat(panier.dataset.montantHt);
        const ttc = parseFloat(panier.dataset.montantTtc);
        const frais_applicable = panier.dataset.fraisPortApplicable === '1';
        const frais_ht = frais_applicable ? parseFloat(panier.dataset.fraisPortHt) : 0.0;
        const frais_ttc = frais_applicable ? parseFloat(panier.dataset.fraisPortTtc) : 0.0;

        tva_requise |= panier.dataset.clientNumFiscalEmpty;

        if (tva_requise) {
            tva_element.textContent = currency_formatter.format(ttc - ht);
            ttc_element.textContent = currency_formatter.format(ttc + frais_ttc);
        }
        else {
            tva_element.textContent = currency_formatter.format(0);
            ttc_element.textContent = currency_formatter.format(ht + frais_ht);
        }
    }

    function updateAdresseDiff(adresse_diff) {
        adresse_facture.classList.toggle('hidden', adresse_diff.checked);
        adresse_facture.disabled = adresse_diff.checked;

        for (const panier of panier_table) {
            updateMontant(panier, (adresse_diff.checked ? pays_livr_selector.selectedOptions[0].dataset.numFiscalRequis : pays_fact_selector.selectedOptions[0].dataset.numFiscalRequis) === '1');
        }
    }

    function updatePays(adresse_diff, pays_livr_selector, pays_fact_selector, panier_table) {
        // On change les frais de port ici, et si on garde la même adresse pour la livraison et la facturation, on change aussi la TVA
        const pays_livr = pays_livr_selector.selectedOptions[0];
        const pays_fact = pays_fact_selector.selectedOptions[0];
        const frais = parseFloat(pays_livr.dataset.fraisPort);
        for (const panier of panier_table) {
            const frais_port_element = panier.querySelector('.panier-montant-frais-port');
            panier.dataset.fraisPortHt = pays_livr.dataset.fraisPort;
            panier.dataset.fraisPortTtc = (parseFloat(pays_livr.dataset.fraisPort) * parseFloat(adresse_diff.checked ? pays_livr.dataset.tauxTva : pays_fact.dataset.tauxTva)).toString();
            const frais_applicable = panier.dataset.fraisPortApplicable === '1';
            frais_port_element.textContent = currency_formatter.format(frais_applicable ? frais : 0.0);

            updateMontant(panier, (adresse_diff.checked ? pays_livr.dataset.numFiscalRequis : pays_fact.dataset.numFiscalRequis) === '1');
        }
    }
});
